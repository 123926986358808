import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/Users/paitony/Works/www/mx/charge_pile/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from "@/components/Pagination";
import CompanyPropertyEdit from "./components/CompanyPropertyEdit.vue";
import { fetchCompanyList, fetchCompanyPropertyList, deleteCompanyProperty, updateCompanyPropertyStatus } from "../../../api/partner";
import { fetchPropertyList } from "@/api/charge";
export default {
  name: "PartnerCompanyPropertyList",
  components: {
    Pagination: Pagination,
    CompanyPropertyEdit: CompanyPropertyEdit
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        filter: {
          company_id: "",
          property_id: "",
          start_time: "",
          end_time: ""
        }
      },
      company_id: "",
      company_name: "",
      bill_date: [],
      // 表格数据
      list: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      //搜索小区
      propertyLoading: false,
      propertyOptions: [],
      companyLoading: false,
      companyOptions: []
    };
  },
  created: function created() {
    this.company_id = this.$route.query ? this.$route.query.company_id : '';
    this.company_name = this.$route.query ? this.$route.query.company_name : '';

    if (this.company_id != "" && this.company_id != undefined) {
      this.companyOptions.push({
        id: this.company_id,
        name: this.company_name
      });
      this.searchForm.filter.company_id = this.company_id;
      this.remoteCompany(this.company_id);
    }

    this.fetchList();
  },
  methods: {
    fetchList: function fetchList() {
      var _this = this;

      this.tableLoading = true;
      fetchCompanyPropertyList(this.searchForm).then(function (response) {
        _this.list = response.data;
        _this.totalPage = response.meta.total;
        _this.tableLoading = false;
      }).catch(function (err) {
        _this.tableLoading = false;

        _this.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    handleAdd: function handleAdd() {
      this.$refs.companyPropertyEdit.showAdd({
        company_id: this.company_id,
        company_name: this.company_name
      });
    },
    handleEdit: function handleEdit(row) {
      this.$refs.companyPropertyEdit.showEdit(row);
    },
    handleSearch: function handleSearch() {
      console.log("搜索");
      this.searchForm.page = 1;
      this.fetchList();
    },
    handlePageCurrent: function handlePageCurrent(val) {
      this.searchForm.page = val;
      this.fetchList();
    },
    handlePageSize: function handlePageSize(val) {
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.fetchList();
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //搜索小区
    remoteProperty: function remoteProperty(query) {
      var _this2 = this;

      if (query !== "") {
        this.propertyLoading = true;
        setTimeout(function () {
          _this2.propertyLoading = false;
          var data = {
            keyword: query
          };
          fetchPropertyList(data).then(function (res) {
            _this2.propertyOptions = res.data;
          });
        }, 100);
      } else {
        this.propertyOptions = [];
      }
    },
    remoteCompany: function remoteCompany(query) {
      var _this3 = this;

      if (query !== "") {
        this.companyLoading = true;
        setTimeout(function () {
          _this3.companyLoading = false;
          var data = {
            keyword: query
          };
          fetchCompanyList(data).then(function (res) {
            console.log(res);
            _this3.companyOptions = res.data;
          });
        }, 100);
      } else {
        this.companyOptions = [];
      }
    },
    handleTime: function handleTime(val) {
      if (val) {
        this.searchForm.filter["start_time"] = val[0];
        this.searchForm.filter["end_time"] = val[1];
      } else {
        this.searchForm.filter["start_time"] = "";
        this.searchForm.filter["end_time"] = "";
      }
    },
    handleStatus: function handleStatus(row) {
      var _this4 = this;

      var status = 'normal';

      if (row.status == 'normal') {
        status = 'disable';
      } else {
        status = 'normal';
      }

      updateCompanyPropertyStatus({
        id: row.id,
        status: status
      }).then(function (res) {
        _this4.$message({
          type: "success",
          message: "修改状态成功"
        });

        row.status = status;
      }).catch(function (err) {
        _this4.$message({
          type: "error",
          message: err.data["message"]
        });
      });
    },
    handleDelete: function handleDelete(row) {
      var _this5 = this;

      var content = "\u60A8\u786E\u8BA4\u8981\u5220\u9664\u8BE5\u516C\u53F8\u7BA1\u7406\u7684\u5C0F\u533A";
      var title = "删除公司管理的小区";
      this.$confirm(content, title, {
        confirmButtonText: "提交",
        cancelButtonText: "取消"
      }).then(function () {
        var id = row.id;
        deleteCompanyProperty(id).then(function (res) {
          _this5.$message({
            type: "success",
            message: "删除公司成功"
          });

          var index = _this5.list.indexOf(row);

          _this5.list.splice(index, 1);
        }).catch(function (err) {
          _this5.$message({
            type: "error",
            message: err.data["message"]
          });
        });
      }).catch(function () {});
    },
    queryData: function queryData(searchForm) {
      this.searchForm.page = 1;
      this.searchForm = _objectSpread(_objectSpread({}, this.searchForm), searchForm);
      this.fetchList();
    }
  }
};