var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, width: "800px", visible: _vm.dialogVisible },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.close
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "dialogForm",
          attrs: {
            "label-position": "right",
            "label-width": "130px",
            model: _vm.companyProperty,
            rules: _vm.rules
          }
        },
        [
          _vm.companyProperty.company_id == ""
            ? _c(
                "el-form-item",
                { attrs: { label: "公司:", prop: "company_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "250px" },
                      attrs: {
                        filterable: "",
                        remote: "",
                        "reserve-keyword": "",
                        placeholder: "请输入公司搜索",
                        "remote-method": _vm.remoteCompany,
                        loading: _vm.companyLoading,
                        clearable: ""
                      },
                      model: {
                        value: _vm.companyProperty.company_id,
                        callback: function($$v) {
                          _vm.$set(_vm.companyProperty, "company_id", $$v)
                        },
                        expression: "companyProperty.company_id"
                      }
                    },
                    _vm._l(_vm.companyOptions, function(item) {
                      return _c(
                        "el-option",
                        {
                          key: item.id,
                          attrs: { label: item.name, value: item.id }
                        },
                        [
                          _c("span", { staticStyle: { float: "left" } }, [
                            _vm._v(_vm._s(item.name))
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _c(
                "el-form-item",
                { attrs: { label: "公司:", size: "normal" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "250px" },
                    attrs: { disabled: true },
                    model: {
                      value: _vm.companyProperty.company.name,
                      callback: function($$v) {
                        _vm.$set(_vm.companyProperty.company, "name", $$v)
                      },
                      expression: "companyProperty.company.name"
                    }
                  })
                ],
                1
              ),
          _vm.companyProperty.id == ""
            ? _c(
                "el-form-item",
                { attrs: { label: "小区:", prop: "property_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "250px" },
                      attrs: {
                        filterable: "",
                        remote: "",
                        "reserve-keyword": "",
                        placeholder: "请输入小区搜索",
                        "remote-method": _vm.remoteProperty,
                        loading: _vm.propertyLoading,
                        clearable: ""
                      },
                      on: { change: _vm.handleChangeProperty },
                      model: {
                        value: _vm.companyProperty.property_id,
                        callback: function($$v) {
                          _vm.$set(_vm.companyProperty, "property_id", $$v)
                        },
                        expression: "companyProperty.property_id"
                      }
                    },
                    _vm._l(_vm.propertyOptions, function(item) {
                      return _c(
                        "el-option",
                        {
                          key: item.id,
                          attrs: { label: item.name, value: item.id }
                        },
                        [
                          _c("span", { staticStyle: { float: "left" } }, [
                            _vm._v(_vm._s(item.name))
                          ])
                        ]
                      )
                    }),
                    1
                  ),
                  _c("div", { staticStyle: { "padding-top": "10px" } }, [
                    _vm._v("\n            累计结算比例:\n            "),
                    _vm.total_settle_ratio == 0
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.total_settle_ratio) + "%")
                        ])
                      : _vm._e(),
                    _vm.total_settle_ratio > 0 && _vm.total_settle_ratio < 100
                      ? _c("span", { staticStyle: { color: "#E6A23C" } }, [
                          _vm._v(_vm._s(_vm.total_settle_ratio) + "%")
                        ])
                      : _vm._e(),
                    _vm.total_settle_ratio == 100
                      ? _c("span", { staticStyle: { color: "#67C23A" } }, [
                          _vm._v(_vm._s(_vm.total_settle_ratio) + "%")
                        ])
                      : _vm._e()
                  ])
                ],
                1
              )
            : _c(
                "el-form-item",
                { attrs: { label: "小区:" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "250px" },
                    attrs: { disabled: true },
                    model: {
                      value: _vm.companyProperty.property_name,
                      callback: function($$v) {
                        _vm.$set(_vm.companyProperty, "property_name", $$v)
                      },
                      expression: "companyProperty.property_name"
                    }
                  }),
                  _c("div", { staticStyle: { "padding-top": "10px" } }, [
                    _vm._v("\n            累计结算比例:\n            "),
                    _vm.total_settle_ratio == 0
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.total_settle_ratio) + "%")
                        ])
                      : _vm._e(),
                    _vm.total_settle_ratio > 0 && _vm.total_settle_ratio < 100
                      ? _c("span", { staticStyle: { color: "#E6A23C" } }, [
                          _vm._v(_vm._s(_vm.total_settle_ratio) + "%")
                        ])
                      : _vm._e(),
                    _vm.total_settle_ratio == 100
                      ? _c("span", { staticStyle: { color: "#67C23A" } }, [
                          _vm._v(_vm._s(_vm.total_settle_ratio) + "%")
                        ])
                      : _vm._e()
                  ])
                ],
                1
              ),
          _c(
            "el-form-item",
            { attrs: { label: "结算比例:", prop: "settle_ratio" } },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "220px" },
                  attrs: { placeholder: "", maxlength: "5" },
                  model: {
                    value: _vm.companyProperty.settle_ratio,
                    callback: function($$v) {
                      _vm.$set(_vm.companyProperty, "settle_ratio", $$v)
                    },
                    expression: "companyProperty.settle_ratio"
                  }
                },
                [_c("template", { slot: "append" }, [_vm._v("%")])],
                2
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态:", prop: "status" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.companyProperty.status,
                    callback: function($$v) {
                      _vm.$set(_vm.companyProperty, "status", $$v)
                    },
                    expression: "companyProperty.status"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: "normal" } }, [
                    _vm._v("正常")
                  ]),
                  _c("el-radio", { attrs: { label: "disable" } }, [
                    _vm._v("禁止")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注:" } },
            [
              _c("el-input", {
                staticStyle: { "max-width": "500px" },
                attrs: { clearable: "", placeholder: "" },
                model: {
                  value: _vm.companyProperty.remark,
                  callback: function($$v) {
                    _vm.$set(_vm.companyProperty, "remark", $$v)
                  },
                  expression: "companyProperty.remark"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "footer-container",
          staticStyle: { "padding-top": "10px" }
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitLoading },
              on: { click: _vm.handleSubmit }
            },
            [_vm._v("保存")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }