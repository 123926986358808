//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { saveCompanyProperty, fetchCompanyList, fetchCompanyPropertyTotalSettleRatio } from "@/api/partner";
import { fetchPropertyList } from "@/api/charge";
import "@/utils/global.js";
export default {
  name: "CompanyPropertyEdit",
  data: function data() {
    return {
      title: "添加结算小区",
      dialogVisible: false,
      companyProperty: {
        id: "",
        company_id: "",
        property_id: "",
        status: "normal",
        settle_ratio: 0,
        remark: ''
      },
      rules: {
        company_id: [{
          required: true,
          message: "请选择公司"
        }],
        property_id: [{
          required: true,
          message: "请选择小区"
        }]
      },
      submitLoading: false,
      //搜索小区
      propertyLoading: false,
      propertyOptions: [],
      companyLoading: false,
      companyOptions: [],
      total_settle_ratio: 0
    };
  },
  methods: {
    showAdd: function showAdd(row) {
      var _this = this;

      this.title = "添加结算小区";
      this.dialogVisible = true;
      this.companyProperty.company_id = row.company_id;
      this.companyProperty.company = {
        name: row.company_name
      };
      this.$nextTick(function () {
        _this.$refs["dialogForm"].clearValidate();
      });
    },
    showEdit: function showEdit(companyProperty) {
      var _this2 = this;

      this.title = "编辑结算小区";
      this.companyProperty = companyProperty;
      this.dialogVisible = true; // this.propertyOptions.push(companyProperty.property);
      // this.remoteProperty(companyProperty.owner.mobile);

      this.handleChangeProperty(companyProperty.property_id);
      this.$nextTick(function () {
        _this2.$refs["dialogForm"].clearValidate();
      });
    },
    handleSubmit: function handleSubmit() {
      var _this3 = this;

      if (this.submitLoading == true) {
        return;
      }

      console.log(this.companyProperty);

      if (parseFloat(this.companyProperty.settle_ratio) + parseFloat(this.total_settle_ratio) > 100) {
        this.$message({
          type: "error",
          message: "结算比例超过100%"
        });
        return false;
      }

      this.$refs["dialogForm"].validate(function (valid) {
        if (valid) {
          _this3.submitLoading = true;
          var data = Object.assign({}, _this3.companyProperty);
          saveCompanyProperty(data).then(function (res) {
            // 成功之后刷新或
            // this.dataList.unshift('返回的数据')
            _this3.$message({
              type: "success",
              message: _this3.title + "成功"
            });

            _this3.dialogVisible = false;
            _this3.submitLoading = false;

            _this3.$emit('fetch-data');
          }).catch(function (err) {
            _this3.submitLoading = false;

            _this3.$message({
              type: "error",
              message: err.data["message"]
            });
          });
        }
      });
      return false;
    },
    remoteProperty: function remoteProperty(query) {
      var _this4 = this;

      if (query !== "") {
        this.propertyLoading = true;
        setTimeout(function () {
          _this4.propertyLoading = false;
          var data = {
            keyword: query
          };
          fetchPropertyList(data).then(function (res) {
            _this4.propertyOptions = res.data;
          });
        }, 100);
      } else {
        this.propertyOptions = [];
      }
    },
    remoteCompany: function remoteCompany(query) {
      var _this5 = this;

      if (query !== "") {
        this.companyLoading = true;
        setTimeout(function () {
          _this5.companyLoading = false;
          var data = {
            keyword: query
          };
          fetchCompanyList(data).then(function (res) {
            _this5.companyOptions = res.data;
          });
        }, 100);
      } else {
        this.companyOptions = [];
      }
    },
    handleChangeProperty: function handleChangeProperty(property_id) {
      var _this6 = this;

      var data = {
        company_id: 0,
        property_id: property_id
      }; // if(this.companyProperty.id==""){
      //   data.company_id = this.companyProperty.company_id;
      // }

      fetchCompanyPropertyTotalSettleRatio(data).then(function (res) {
        _this6.total_settle_ratio = res.data.total_settle_ratio;
      });
    },
    close: function close() {
      this.$refs['dialogForm'].resetFields();
      this.dialogVisible = false;
      this.companyProperty = {
        id: "",
        company_id: "",
        property_id: "",
        status: "normal",
        settle_ratio: 0,
        remark: ''
      };
      this.total_settle_ratio = 0;
    }
  }
};