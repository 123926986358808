var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { staticClass: "form-container" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              staticClass: "search_form",
              attrs: { model: _vm.searchForm, inline: "" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "公司:", prop: "filter.company_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "220px" },
                      attrs: {
                        filterable: "",
                        remote: "",
                        "reserve-keyword": "",
                        placeholder: "请输入公司搜索",
                        "remote-method": _vm.remoteCompany,
                        loading: _vm.companyLoading,
                        clearable: ""
                      },
                      model: {
                        value: _vm.searchForm.filter.company_id,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm.filter, "company_id", $$v)
                        },
                        expression: "searchForm.filter.company_id"
                      }
                    },
                    _vm._l(_vm.companyOptions, function(item) {
                      return _c(
                        "el-option",
                        {
                          key: item.id,
                          attrs: { label: item.name, value: item.id }
                        },
                        [
                          _c("span", { staticStyle: { float: "left" } }, [
                            _vm._v(_vm._s(item.name))
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "小区:", prop: "filter.property_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "180px" },
                      attrs: {
                        filterable: "",
                        remote: "",
                        "reserve-keyword": "",
                        placeholder: "请输入小区搜索",
                        "remote-method": _vm.remoteProperty,
                        loading: _vm.propertyLoading,
                        clearable: ""
                      },
                      model: {
                        value: _vm.searchForm.filter.property_id,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm.filter, "property_id", $$v)
                        },
                        expression: "searchForm.filter.property_id"
                      }
                    },
                    _vm._l(_vm.propertyOptions, function(item) {
                      return _c(
                        "el-option",
                        {
                          key: item.id,
                          attrs: { label: item.name, value: item.id }
                        },
                        [
                          _c("span", { staticStyle: { float: "left" } }, [
                            _vm._v(_vm._s(item.name))
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.handleSearch }
                    },
                    [_vm._v("搜索")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "batch-container" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.handleAdd }
            },
            [_vm._v("添加结算小区")]
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "table-container" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading"
                }
              ],
              attrs: { data: _vm.list, border: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "id",
                  label: "ID",
                  "header-align": "center",
                  align: "center",
                  width: "80px"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "company",
                  label: "公司信息",
                  "header-align": "center",
                  align: "center",
                  width: "150px"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c("div", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(scope.row.company.name) +
                                  "\n            "
                              )
                            ]),
                            scope.row.company.type == "agent"
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v("代理")
                                ])
                              : _vm._e(),
                            scope.row.company.type == "investor"
                              ? _c("el-tag", { attrs: { type: "warning" } }, [
                                  _vm._v("投资人")
                                ])
                              : _vm._e(),
                            scope.row.company.type == "property"
                              ? _c("el-tag", { attrs: { type: "warning" } }, [
                                  _vm._v("物业")
                                ])
                              : _vm._e(),
                            scope.row.company.type == "intermediary"
                              ? _c("el-tag", { attrs: { type: "info" } }, [
                                  _vm._v("居间人员")
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "property_name",
                  label: "小区",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "settle_ratio",
                  label: "结算比例",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.settle_ratio) +
                              "%\n          "
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  label: "状态",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-tag",
                              {
                                attrs: {
                                  type:
                                    scope.row.status == "normal"
                                      ? "success"
                                      : "info"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row.status == "normal"
                                      ? "正常"
                                      : "禁用"
                                  )
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "remark",
                  label: "备注",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "created_at",
                  label: "创建时间",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "updated_at",
                  label: "更新时间",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "header-align": "center",
                  align: "center",
                  width: "200px"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.handleEdit(scope.row)
                              }
                            }
                          },
                          [_vm._v("\n           设置结算比例\n          ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.handleStatus(scope.row)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n           " +
                                _vm._s(
                                  scope.row.status != "normal" ? "启用" : "禁用"
                                ) +
                                "\n          "
                            )
                          ]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.handleDelete(scope.row)
                              }
                            }
                          },
                          [_vm._v("\n           删除\n          ")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.totalPage > 0,
            expression: "totalPage > 0"
          }
        ],
        attrs: {
          total: _vm.totalPage,
          page: _vm.searchForm.page,
          limit: _vm.searchForm.per_page
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.searchForm, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.searchForm, "per_page", $event)
          },
          pagination: _vm.fetchList
        }
      }),
      _c("company-property-edit", {
        ref: "companyPropertyEdit",
        on: { "fetch-data": _vm.queryData }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }